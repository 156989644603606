<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> Étapes / avancement </h3>

        <p class="subtitle-1 grey--text text--darken-2">
            Visualisez ici le détail des différentes étapes relatives à votre acquisition du lot
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.product.reference }}</v-chip>
            sur le programme
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.program.title }}</v-chip>.
        </p>

        <v-row>
            <v-col>
                <v-stepper :value="selectedSale.step">
                    <v-stepper-header>
                        <v-stepper-step :complete="selectedSale.step > 1" :color="getStepColor(1)" step="1">
                            <v-badge :value="selectedSale.step === 1" color="red" dot>
                                <span class="mr-2">
                                    Signature contrat
                                </span>
                            </v-badge>
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="selectedSale.step > 2" :color="getStepColor(2)" step="2">
                            <v-badge :value="selectedSale.step === 2" color="red" dot>
                                <span class="mr-2">
                                    Délai SRU
                                </span>
                            </v-badge>
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="selectedSale.step > 3" :color="getStepColor(3)" step="3">
                            <v-badge :value="selectedSale.step === 3" color="red" dot>
                                <span class="mr-2">
                                    Financement
                                </span>
                            </v-badge>
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="selectedSale.step > 4" :color="getStepColor(4)" step="4">
                            <v-badge :value="selectedSale.step === 4" color="red" dot>
                                <span class="mr-2">
                                    Acte authentique
                                </span>
                            </v-badge>
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="selectedSale.step > 5" :color="getStepColor(5)" step="5">
                            <v-badge :value="selectedSale.step === 5" color="red" dot>
                                <span class="mr-2">
                                    Appels de fonds
                                </span>
                            </v-badge>
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="selectedSale.step > 6" :color="getStepColor(6)" step="6">
                            <v-badge :value="selectedSale.step === 6" color="red" dot>
                                <span class="mr-2">
                                    Livraison
                                </span>
                            </v-badge>
                        </v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" lg="4">
                <v-card class="py-0 fill-height" :elevation="getElevation(1)">
                    <v-card-title class="text-h6 py-0 white--text" :class="getStepColor(1)">
                        Signature contrat

                        <v-spacer />

                        <v-chip v-if="selectedSale.step === 1" label small class="app-blue--text" color="white">
                            Vous êtes ici
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="white text--primary py-0">
                        <v-list dense light>
                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        {{ getConnectedUserSignature(selectedSale).signed | toDate() }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Vous
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-for="coacq of coacqs " :value="coacq" :key="coacq.email">
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        {{ coacq.signed | toDate() }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        {{coacq.name + ' ' + coacq.firstname}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        {{ getPromoteurSignature(selectedSale).signed | toDate() }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Promoteur
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card class="py-0 fill-height" :elevation="getElevation(2)">
                    <v-card-title class="text-h6 py-0 white--text" :class="getStepColor(2)">
                        Délai SRU

                        <v-spacer />

                        <v-chip v-if="selectedSale.step === 2" label small class="app-blue--text" color="white">
                            Vous êtes ici
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="white text--primary py-0">
                        <v-list dense light>
                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        {{ getConnectedUserSignature(selectedSale).notificationSent | toDate() }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Envoi notification
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-for="coacq of coacqs " :value="coacq" :key="coacq.email">
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        {{ coacq.notificationSent | toDate() }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        {{coacq.name + ' ' + coacq.firstname}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        {{ selectedSale.retractationlimitdate | toDate() }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date limite de rétractation
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card class="py-0 fill-height" :elevation="getElevation(3)">
                    <v-card-title class="text-h6 py-0 white--text" :class="getStepColor(3)">
                        Financement

                        <v-spacer />

                        <v-chip v-if="selectedSale.step === 3" label small class="app-blue--text" color="white">
                            Vous êtes ici
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="white text--primary py-0">
                        <v-list dense light>
                            <template v-if="selectedSale.noloan">
                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Aucun prêt sollicité
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>

                            <template v-else>

                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <template v-if="selectedSale.loanapplicationlimitdate">
                                                {{ selectedSale.loanapplicationlimitdate | toDate() }}
                                            </template>
                                            <template v-else>
                                                <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                            </template>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Date limite de demande
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <template v-if="selectedSale.loanapplication">
                                                {{ selectedSale.loanapplication | toDate() }}
                                            </template>
                                            <template v-else>
                                                <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                            </template>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Date de dépôt des pièces
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <template v-if="selectedSale.loanoffer">
                                                {{ selectedSale.loanoffer | toDate() }}
                                            </template>
                                            <template v-else>
                                                <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                            </template>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Date d'accord de l'offre de prêt
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <template v-if="selectedSale.loanvalidationlimitdate">
                                                {{ selectedSale.loanvalidationlimitdate | toDate() }}
                                            </template>
                                            <template v-else>
                                                <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                            </template>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Date limite d'édition de l’offre de prêt
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <template v-if="selectedSale.availabilitydate">
                                                {{ selectedSale.availabilitydate | toDate() }}
                                            </template>
                                            <template v-else>
                                                <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                            </template>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Date d'édition de l'offre de prêt
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content class="pt-0 pb-2">
                                        <v-list-item-title>
                                            <template v-if="selectedSale.notarynotificationdate">
                                                {{ selectedSale.notarynotificationdate | toDate() }}
                                            </template>
                                            <template v-else>
                                                <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                            </template>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Date d'envoi copie notaire
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" lg="4">
                <v-card class="py-0 fill-height" :elevation="getElevation(4)">
                    <v-card-title class="text-h6 py-0 white--text" :class="getStepColor(4)">
                        Acte authentique

                        <v-spacer />

                        <v-chip v-if="selectedSale.step === 4" label small class="app-blue--text" color="white">
                            Vous êtes ici
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="white text--primary py-0">
                        <v-list dense light>
                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.notarylimitdate">
                                            {{ selectedSale.notarylimitdate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date limite de signature définitive
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.contractsentdate">
                                            {{ selectedSale.contractsentdate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date d'envoi projet d'acte
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.notaryappdate">
                                            {{ selectedSale.notaryappdate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date de signature prévue
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.deeddate">
                                            {{ selectedSale.deeddate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date de signature notaire
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card class="py-0 fill-height" :elevation="getElevation(5)">
                    <v-card-title class="text-h6 py-0 white--text" :class="getStepColor(5)">
                        Appels de fonds

                        <v-spacer />

                        <v-chip v-if="selectedSale.step === 5" label small class="app-blue--text" color="white">
                            Vous êtes ici
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="white text--primary py-0">
                        <v-list dense light>
                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.fundcalldate">
                                            {{ selectedSale.fundcalldate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Dernier appel
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.paymentdate">
                                            {{ selectedSale.paymentdate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Dernier règlement
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="4">
                <v-card class="y-0" :elevation="getElevation(6)">
                    <v-card-title class="text-h6 py-0 white--text" :class="getStepColor(6)">
                        Livraison

                        <v-spacer />

                        <v-chip v-if="selectedSale.step === 6" label small class="app-blue--text" color="white">
                            Vous êtes ici
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="white text--primary py-0">
                        <v-list dense light>
                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.plannedDeliveryDate">
                                            {{ selectedSale.plannedDeliveryDate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date de livraison prévisionnelle contrat de réservation
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.deliverydate">
                                            {{ selectedSale.deliverydate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date de réception
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.deliveryactionlimitdate">
                                            {{ selectedSale.deliveryactionlimitdate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date limite de levée des réserves
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content class="pt-0 pb-2">
                                    <v-list-item-title>
                                        <template v-if="selectedSale.deliveryactiondate">
                                            {{ selectedSale.deliveryactiondate | toDate() }}
                                        </template>
                                        <template v-else>
                                            <v-icon x-small color="grey lighten-1"> mdi-dots-horizontal </v-icon>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date réception LAR réserve
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import salesMixin from '../mixins/sales';

export default {
    name: 'Steps',

    mixins: [salesMixin],

    data: () => ({
        coacqs: []
    }),

    methods: {
        getStepColor(stepId) {
            if (this.selectedSale.step > stepId) {
                return 'app-green';
            } else if (this.selectedSale.step < stepId) {
                return 'grey ligthen-2';
            } else {
                return 'app-blue';
            }
        },
        getElevation(stepId) {
            if (this.selectedSale.step == stepId) {
                return 24;
            } else {
                return 5;
            }
        }
    },
    watch: {
        selectedSale() {
            this.coacqs = this.getOtherUsersSignature(this.selectedSale);
        }
    },
    created() {
        this.coacqs = this.getOtherUsersSignature(this.selectedSale);
    }
};
</script>
